import IProjectAnswer from '../models/answers/IProjectAnswer';
import IQuestion from '../models/IQuestion';

export function adapterFromApiToForm(
	partialAnswers: IProjectAnswer,
	questions: IQuestion[]
) {
	const result: Record<string, unknown> = {
		name: partialAnswers?.name,
		hospital: partialAnswers?.workplace,
	};

	partialAnswers.projectAnswers?.forEach((question) => {
		const toAdd = question.answers.map((answer) => answer.value);
		const currentQuestionObject = questions.find(
			(currentQuestion) => currentQuestion.id === question.id
		);
		if (!currentQuestionObject) return;

		switch (currentQuestionObject.answerType) {
			case 'text':
			case 'number':
			case 'singleChoice':
				result[`input_${(question.id as string) ?? ''}`] = toAdd[0];
				break;
			default:
				result[`input_${(question.id as string) ?? ''}`] = toAdd;
				break;
		}

		currentQuestionObject?.options?.forEach((option, index) => {
			if (option.open) {
				result[`input_${(question.id as string) ?? ''}_open_${index}`] =
					question.answers.find(
						(answer) => answer.value === option.key
					)?.openField;
			}
		});
	});
	return result;
}
