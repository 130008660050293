import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { selectLaboratory } from '../../../../../redux-store/features/global';
import IQuestion from '../../../../../tools/models/IQuestion';
import { downloadImage } from '../../../../../tools/downloadImage';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
// import { generateColorPalette } from '../../../../../tools/colorPalette';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
);

const options = {
	responsive: true,
	plugins: {
		legend: {
			position: 'right' as const,
		},
		title: {
			display: false,
		},
	},
	maintainAspectRatio: false,
};

const PieChart = (props: {
	categories: string[];
	questionAnswersCount: number[];
	question: IQuestion;
}) => {
	const laboratory = useSelector(selectLaboratory);

	const { categories, questionAnswersCount, question } = props;

	// const colorPalette = generateColorPalette(
	// 	laboratory?.primaryColor ?? '#000000',
	// 	categories.length
	// );

	const data = {
		labels: categories,
		datasets: [
			{
				label: 'Cantidad de respuestas',
				data: questionAnswersCount,
				backgroundColor: [
					'rgba(255, 99, 132, 0.2)',
					'rgba(54, 162, 235, 0.2)',
					'rgba(255, 206, 86, 0.2)',
					'rgba(75, 192, 192, 0.2)',
					'rgba(153, 102, 255, 0.2)',
					'rgba(255, 159, 64, 0.2)',
				],
				borderColor: [
					'rgba(255, 99, 132, 1)',
					'rgba(54, 162, 235, 1)',
					'rgba(255, 206, 86, 1)',
					'rgba(75, 192, 192, 1)',
					'rgba(153, 102, 255, 1)',
					'rgba(255, 159, 64, 1)',
				],
				borderWidth: 1,
			},
		],
	};

	return (
		<div className="mb-4 flex flex justify-center w-full max-h-[500px] items-end">
			<div className="max-w-[500px]" id={`report_pie_${question.id}`}>
				<Pie height={300} width={500} data={data} options={options} />
			</div>
			<Button
				className="ml-4 download-img-btn"
				onClick={() => downloadImage(`report_pie_${question.id}`)}
				icon={<DownloadOutlined />}
			/>
		</div>
	);
};

export default PieChart;
