import { Spin, Radio, RadioChangeEvent, InputNumber, Button } from 'antd';
import IQuestion from '../../../../tools/models/IQuestion';
import { useGetQuestionAnswersQuery } from '../../../../redux-store/services/advisoryApi';

import PieChart from './charts/PieChart';
import BarChart from './charts/BarChart';
import { useState } from 'react';
import {
	BarChartOutlined,
	BarsOutlined,
	DownloadOutlined,
	PieChartOutlined,
} from '@ant-design/icons';
import { createIntervals } from '../../../../tools/helpers';
import * as XLSX from 'xlsx';

const OpenFieldNumberReport = (props: { question: IQuestion }) => {
	const { question } = props;
	const [chartType, setChartType] = useState('bar');
	const [intervalsNumber, setIntervalsNumber] = useState(5);
	//

	const { data: questionAnswers, isLoading: isLoadingQuestionAnswers } =
		useGetQuestionAnswersQuery(question.id);

	const answersArray =
		questionAnswers?.answers
			?.map((answer) => Number(answer.answer[0].value))
			?.sort() ?? [];

	const intervals = createIntervals(answersArray, intervalsNumber);

	const csvData =
		questionAnswers?.answers?.map((answer) => {
			return {
				id: answer.id,
				respuesta: answer.answer[0].value,
			};
		}) ?? [];

	return (
		<Spin spinning={isLoadingQuestionAnswers}>
			<div className="ml-4 text-gray-700 mb-10">
				<div>
					<p className=" mb-6 text-left">{question.text}</p>
				</div>
				<div className="text-left ">
					<div>
						Promedio:{' '}
						{answersArray.reduce((a, b) => a + b, 0) / answersArray.length}
					</div>
				</div>
				<div className="flex justify-between items-center mt-5 mb-10 hide-in-print">
					<div>
						Cantidad de intervalos:
						<InputNumber
							className="ml-2 w-20"
							min={1}
							max={10}
							defaultValue={intervalsNumber}
							onChange={(e) => setIntervalsNumber(e ?? intervalsNumber)}
						/>
					</div>
					<Radio.Group
						onChange={(e: RadioChangeEvent) =>
							setChartType(e?.target?.value as string)
						}
						defaultValue={chartType}
					>
						<Radio.Button value="pie">
							<PieChartOutlined className="mr-2" />
							Gráfico circular
						</Radio.Button>
						<Radio.Button value="bar">
							<BarChartOutlined className="mr-2" />
							Barras vertical
						</Radio.Button>
						<Radio.Button value="bar-h">
							<BarsOutlined className="mr-2" />
							Barras hotizontal
						</Radio.Button>
					</Radio.Group>
				</div>
				{chartType === 'bar' ? (
					<BarChart
						categories={intervals.map(
							(item) => `De ${item.start} a ${item.end}`
						)}
						questionAnswersCount={intervals.map((item) => item.numbers.length)}
						question={question}
					/>
				) : null}
				{chartType === 'bar-h' ? (
					<BarChart
						categories={intervals.map(
							(item) => `De ${item.start} a ${item.end}`
						)}
						questionAnswersCount={intervals.map((item) => item.numbers.length)}
						horizontal
						question={question}
					/>
				) : null}
				{chartType === 'pie' ? (
					<PieChart
						categories={intervals.map(
							(item) => `De ${item.start} a ${item.end}`
						)}
						questionAnswersCount={intervals.map((item) => item.numbers.length)}
						question={question}
					/>
				) : null}

				<div className="flex justify-end">
					<Button
						icon={<DownloadOutlined />}
						className=" py-4 rounded-lg flex justify-center items-center hide-in-print"
						onClick={() => {
							const wb = XLSX.utils.book_new();
							const ws = XLSX.utils.json_to_sheet([]);
							XLSX.utils.sheet_add_aoa(ws, [['id', 'respuesta']]);
							XLSX.utils.sheet_add_json(ws, csvData, {
								origin: 'A2',
								skipHeader: true,
							});
							XLSX.utils.book_append_sheet(wb, ws, 'Respuestas');
							XLSX.writeFile(wb, 'Respuestas.xlsx');
						}}
					>
						Descargar respuestas
					</Button>
				</div>
			</div>
		</Spin>
	);
};

export default OpenFieldNumberReport;
