import { Button, Radio, RadioChangeEvent, Spin } from 'antd';
import IQuestion from '../../../../tools/models/IQuestion';
import { useGetQuestionAnswersQuery } from '../../../../redux-store/services/advisoryApi';
import WordCloud from './charts/WordCloud';
import {
	AntCloudOutlined,
	CloseOutlined,
	DownloadOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import * as XLSX from 'xlsx';

const OpenFieldTextAreaReport = (props: { question: IQuestion }) => {
	const { question } = props;

	const [chartType, setChartType] = useState('wordcloud');

	const { data: questionAnswers, isLoading: isLoadingQuestionAnswers } =
		useGetQuestionAnswersQuery(question.id);

	const textFromAnswers =
		questionAnswers?.answers?.map((answer) => answer.answer[0].value) ?? [];
	const text = textFromAnswers.join(' ');

	const csvData =
		questionAnswers?.answers?.map((answer) => {
			return {
				id: answer.id,
				respuesta: answer.answer[0].value,
			};
		}) ?? [];

	return (
		<Spin spinning={isLoadingQuestionAnswers}>
			<div>
				<p className="ml-4 text-gray-700 mb-6 text-left">{question.text}</p>
			</div>
			<div className="flex justify-end mb-10">
				<Radio.Group
					onChange={(e: RadioChangeEvent) =>
						setChartType(e?.target?.value as string)
					}
					defaultValue={chartType}
					style={{ marginTop: 16 }}
					className="hide-in-print"
				>
					<Radio.Button value="none">
						<CloseOutlined className="mr-2" />
						Sin gráfico
					</Radio.Button>
					<Radio.Button value="wordcloud">
						<AntCloudOutlined className="mr-2" />
						Nube de palabras
					</Radio.Button>
				</Radio.Group>
			</div>
			{chartType === 'wordcloud' ? (
				<div>
					<WordCloud text={text} question={question} />
				</div>
			) : null}
			<div className="flex justify-end mb-10">
				<Button
					icon={<DownloadOutlined />}
					className=" py-4 rounded-lg flex justify-center items-center hide-in-print"
					onClick={() => {
						const wb = XLSX.utils.book_new();
						const ws = XLSX.utils.json_to_sheet([]);
						XLSX.utils.sheet_add_aoa(ws, [['id', 'respuesta']]);
						XLSX.utils.sheet_add_json(ws, csvData, {
							origin: 'A2',
							skipHeader: true,
						});
						XLSX.utils.book_append_sheet(wb, ws, 'Respuestas');
						XLSX.writeFile(wb, 'Respuestas.xlsx');
					}}
				>
					Descargar respuestas
				</Button>
			</div>
		</Spin>
	);
};

export default OpenFieldTextAreaReport;
