import { Form, Button, Row, Col, Spin, message, Result } from 'antd';
import IProject from '../../../tools/models/IProject';

import ProjectSection from './ProjectSection';
import ILaboratory from '../../../tools/models/ILaboratory';
import OpenFieldInput from './inputs/OpenFieldInput';
import { useEffect, useState } from 'react';
import { getQuestionsFromProject } from '../../../tools/helpers';
import {
	usePostAdvisoryAnswersMutation,
	usePostUnfinishedAdvisoryAnswersMutation,
} from '../../../redux-store/services/advisoryApi';
import { useParams } from 'react-router-dom';
import SaveLaterModal from './SaveLaterModal';
import IProjectAnswer from '../../../tools/models/answers/IProjectAnswer';
import IUnfinishedProjectAnswer from '../../../tools/models/answers/IUnfinishedProjectAnswer';
import { adapterFromFormToApi } from '../../../tools/adapters/adapterFromFormToApi';
import { adapterFromApiToForm } from '../../../tools/adapters/adapterFromApiToForm';
import { SmileOutlined } from '@ant-design/icons';

const FormComponent = (props: {
	project: IProject;
	laboratory: ILaboratory;
	partialAnswers?: IProjectAnswer;
}) => {
	const { project, laboratory, partialAnswers } = props;
	const { id } = useParams();
	const [hovered, setHovered] = useState(false);
	const [showSaveModal, setShowSaveModal] = useState(false);
	const [form] = Form.useForm();

	const buttonStyle = {
		borderColor: laboratory.primaryColor,
		color: laboratory.primaryColor,
	};

	const [postAdvisoryAnswers, { data, error, isLoading, isSuccess }] =
		usePostAdvisoryAnswersMutation();

	const [
		postUnfinishedAdvisoryAnswers,
		{
			data: unfinshedAdvisoryData,
			error: errorUnfinishedAdvisoty,
			isLoading: isLoadingUnfinishedAdvisory,
			isSuccess: isUnfinishedAdvisorySuccess,
		},
	] = usePostUnfinishedAdvisoryAnswersMutation();

	const onFinish = (values: any) => {
		const questions = getQuestionsFromProject(project);
		const toSubmit = adapterFromFormToApi(values, questions);
		toSubmit.id = id;
		void postAdvisoryAnswers(toSubmit);
	};

	const saveUnfinishedAdvisory = (email: string) => {
		const questions = getQuestionsFromProject(project);
		const projectAnswer: IProjectAnswer = adapterFromFormToApi(
			form.getFieldsValue(),
			questions
		);
		projectAnswer.id = id;

		const toSubmit: IUnfinishedProjectAnswer = {
			form: projectAnswer,
			email,
		};
		void postUnfinishedAdvisoryAnswers(toSubmit);
	};

	useEffect(() => {
		if (isUnfinishedAdvisorySuccess) {
			void message.success(
				'Formulario guardado con éxito. Recibirá un correo para terminarlo cuando desee.'
			);
		}
	}, [isUnfinishedAdvisorySuccess]);

	useEffect(() => {
		if (errorUnfinishedAdvisoty) {
			void message.error('El formulario no pudo ser guardado');
		}
	}, [errorUnfinishedAdvisoty]);

	return (
		<Row className="w-full justify-center advisory">
			<Col xs={22} sm={22} md={18} lg={18} xl={18}>
				<div className="w-full mx-auto mt-5 px-4 md:px-10 py-6 mb-20 bg-white rounded-lg shadow-lg">
					{isSuccess ? (
						<Result
							icon={
								<SmileOutlined
									className="mb-4"
									style={{ fontSize: '50px', color: '#52c41a' }}
								/>
							}
							title="Sus respuestas han sido enviadas correctamente"
							subTitle="Muchas gracias"
						/>
					) : (
						<div>
							<p className="font-semibold mb-6 text-left">
								{project.description}
							</p>
							<Spin spinning={isLoading || isLoadingUnfinishedAdvisory}>
								<Form
									name="project-form"
									onFinish={onFinish}
									form={form}
									initialValues={
										partialAnswers
											? adapterFromApiToForm(
													partialAnswers,
													getQuestionsFromProject(project)
											  )
											: {}
									}
								>
									<Row className="mt-10 mb-10 md:mb-10">
										<Col xs={24} md={12} className="md:pr-8">
											<OpenFieldInput
												label="Nombre"
												name="name"
												borders={false}
											/>
										</Col>
										<Col xs={24} md={12} className="md:pl-8">
											<OpenFieldInput
												label="Hospital"
												name="hospital"
												borders={false}
											/>
										</Col>
									</Row>
									{project.projectSections.map((projectSection) => (
										<ProjectSection
											form={form}
											key={projectSection.id}
											projectSection={projectSection}
										/>
									))}
									<div className="mb-6">
										<Form.Item className="pr-4">
											<div className="flex lg:justify-end justify-center">
												<Button
													onClick={() => setShowSaveModal(true)}
													style={hovered ? buttonStyle : {}}
													onMouseEnter={() => setHovered(true)}
													onMouseLeave={() => setHovered(false)}
													className="mr-4 px-5 py-4 rounded-lg flex justify-center items-center disabled:opacity-50"
												>
													Terminar después
												</Button>
												<Button
													htmlType="submit"
													className="px-10 py-4 rounded-lg flex justify-center items-center font-semibold disabled:opacity-50"
													style={{
														background: laboratory.primaryColor,
														color: laboratory.secondaryColor,
													}}
												>
													Enviar
												</Button>
											</div>
										</Form.Item>
									</div>
								</Form>
							</Spin>
						</div>
					)}
					{showSaveModal ? (
						<SaveLaterModal
							handleOk={(email: string) => {
								saveUnfinishedAdvisory(email);
								setShowSaveModal(false);
							}}
							handleCancel={() => setShowSaveModal(false)}
						/>
					) : null}
				</div>
			</Col>
		</Row>
	);
};

export default FormComponent;
