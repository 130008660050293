import { Form, FormInstance, Input, Radio, RadioChangeEvent } from 'antd';
import IQuestion from '../../../../tools/models/IQuestion';
import { useEffect, useState } from 'react';

const { TextArea } = Input;
const { Group: RadioGroup } = Radio;

const FormComponent = (props: {
	question: IQuestion;
	form: FormInstance<any>;
}) => {
	const { question, form } = props;

	const initialValue: string =
		form.getFieldValue(`input_${question?.id}`) || null;

	console.log(initialValue);

	const [radioValue, setRadioValue] = useState<string | null>(initialValue);

	const handleRadioChange = (e: RadioChangeEvent) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		setRadioValue(e.target.value);
	};

	useEffect(() => {
		// Reset the input value when it becomes disabled
		question?.options?.forEach((option, index) => {
			if (option.open && radioValue !== option.key) {
				form.setFieldsValue({
					[`input_${question.id}_open_${index}`]: '',
				});
			}
		});
	}, [radioValue]);

	return (
		<Form.Item
			name={`input_${question.id}`}
			className="px-4 py-2 borders-error"
			label={question.text}
			labelCol={{ span: 24 }}
			rules={[
				{
					required: true,
					message: 'Este campo es obligatorio',
				},
			]}
		>
			<RadioGroup
				className="mb-2 flex-col w-full"
				onChange={handleRadioChange}
				value={radioValue}
			>
				<div>{form.getFieldError(`input_${question.id}`) || ''}</div>
				{question?.options?.map((choice, index) => {
					return (
						<div key={choice.key} className="mt-2 text-left">
							<Radio value={choice.key}>{choice.text}</Radio>
							{choice.open ? (
								<Form.Item
									name={`input_${question.id}_open_${index}`}
									className="mt-4 checkbox-open-input"
									rules={[
										{
											required: radioValue === choice.key,
											message: '',
										},
									]}
								>
									<TextArea
										disabled={radioValue !== choice.key}
										className=""
										autoSize={{ minRows: 3, maxRows: 3 }}
									/>
								</Form.Item>
							) : null}
						</div>
					);
				})}
			</RadioGroup>
		</Form.Item>
	);
};

export default FormComponent;
