import IProjectSection from '../../../tools/models/IProjectSection';

import MultipleChoiceInputReport from './inputs/MultipleChoiceInputReport';
import OpenFieldTextAreaReport from './inputs/OpenFieldTextAreaReport';
import OpenFieldNumberReport from './inputs/OpenFieldNumberReport';

const ProjectSectionReport = (props: { projectSection: IProjectSection }) => {
	const { projectSection } = props;
	return (
		<div className="mb-8">
			<h2 className="text-lg font-semibold mb-2">{projectSection.name}</h2>
			<p className="ml-4 text-gray-600 mb-4 text-left">
				{projectSection.description}
			</p>
			{projectSection.questions.map((question) => (
				<div key={question.id} className="mb-10">
					{question.answerType === 'multipleChoice' ||
					question.answerType === 'singleChoice' ? (
						<MultipleChoiceInputReport question={question} />
					) : null}
					{question.answerType === 'text' ? (
						<OpenFieldTextAreaReport question={question} />
					) : null}
					{question.answerType === 'number' ? (
						<OpenFieldNumberReport question={question} />
					) : null}
				</div>
			))}
		</div>
	);
};

export default ProjectSectionReport;
