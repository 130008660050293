import { extractAndCountWords } from '../../../../../tools/helpers';
import ReactWordcloud, { Options } from 'react-wordcloud';
import { WordFrequency } from '../../../../../tools/models/charts/WordFrequency';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { downloadImage } from '../../../../../tools/downloadImage';
import IQuestion from '../../../../../tools/models/IQuestion';

const WordCloud = (props: { text: string; question: IQuestion }) => {
	const { text, question } = props;
	const size: [number, number] = [500, 200];

	const options: any = {
		rotations: 2,
		scale: 'sqrt',
		// colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
		enableTooltip: true,
		deterministic: false,
		spiral: 'archimedean',
		fontFamily: 'impact',
		fontSizes: [5, 40],
		rotationAngles: [0, 90],
		transitionDuration: 1000,
	};
	const words: WordFrequency[] = extractAndCountWords(text);
	return (
		<div className="mb-4 flex flex justify-center w-full max-h-[500px] items-end">
			<div className="max-w-[500px]" id={`report_word_cloud_${question.id}`}>
				<ReactWordcloud words={words} size={size} options={options} />
			</div>
			<Button
				className="download-img-btn"
				onClick={() => downloadImage(`report_word_cloud_${question.id}`)}
				icon={<DownloadOutlined />}
			/>
		</div>
	);
};

export default WordCloud;
