import IProjectSection from '../../../tools/models/IProjectSection';

import SingleChoiceInput from './inputs/SingleChoiceInput';
import MultipleChoiceInput from './inputs/MultipleChoiceInput';
import { FormInstance } from 'antd';
import OpenFieldTextArea from './inputs/OpenFieldTextArea';
import OpenFieldNumber from './inputs/OpenFieldNumber';

const ProjectSection = (props: {
	projectSection: IProjectSection;
	form: FormInstance<any>;
}) => {
	const { projectSection, form } = props;
	return (
		<div className="mb-6">
			<h2 className="text-lg font-semibold mb-2">{projectSection.name}</h2>
			<p
				className={`ml-4 ${
					projectSection.name ? 'text-gray-600' : ''
				} mb-4 text-left`}
			>
				{projectSection.description}
			</p>
			{projectSection.questions.map((question) => (
				<div key={question.id} className="mb-5">
					{question.answerType === 'singleChoice' ? (
						<SingleChoiceInput form={form} question={question} />
					) : null}
					{question.answerType === 'multipleChoice' ? (
						<MultipleChoiceInput form={form} question={question} />
					) : null}
					{question.answerType === 'text' ? (
						<OpenFieldTextArea question={question} />
					) : null}
					{question.answerType === 'number' ? (
						<OpenFieldNumber question={question} />
					) : null}
				</div>
			))}
		</div>
	);
};

export default ProjectSection;
