import { Outlet /*Link*/ } from 'react-router-dom';
import HeaderContainer from '../components/layout/HeaderContainer';
import { Layout } from 'antd';
import FooterContainer from '../components/layout/FooterContainer';

const { Content } = Layout;

function LayoutContainer() {
	return (
		<Layout className=" min-h-screen bg-gray-100">
			<HeaderContainer />
			<Content className="" style={{ zIndex: 2 }}>
				<Outlet />
			</Content>
			<FooterContainer />
		</Layout>
	);
}

export default LayoutContainer;
