import { Spin, Radio, RadioChangeEvent, Button } from 'antd';
import IQuestion from '../../../../tools/models/IQuestion';
import { useGetQuestionAnswersQuery } from '../../../../redux-store/services/advisoryApi';

import PieChart from './charts/PieChart';
import BarChart from './charts/BarChart';
import { useState } from 'react';
import {
	BarChartOutlined,
	BarsOutlined,
	PieChartOutlined,
} from '@ant-design/icons';

const MultipleChoiceInputReport = (props: { question: IQuestion }) => {
	const { question } = props;
	const [chartType, setChartType] = useState('bar');
	//

	const { data: questionAnswers, isLoading: isLoadingQuestionAnswers } =
		useGetQuestionAnswersQuery(question.id);

	const answerCounts = Array.from(question?.options ?? [], () => 0);
	question?.options?.forEach((option, index) => {
		answerCounts[index] =
			questionAnswers?.answers
				?.map((item) => item.answer)
				?.filter((current) => {
					return current?.find((item) => item?.value === option.key);
				}).length ?? 0;
	});

	return (
		<Spin spinning={isLoadingQuestionAnswers}>
			<div>
				<p className="ml-4 text-gray-700 mb-6 text-left">{question.text}</p>
			</div>
			<div className="flex justify-end mb-10">
				<Radio.Group
					onChange={(e: RadioChangeEvent) =>
						setChartType(e?.target?.value as string)
					}
					defaultValue={chartType}
					style={{ marginTop: 16 }}
					className="hide-in-print"
				>
					<Radio.Button value="pie">
						<PieChartOutlined className="mr-2" />
						Gráfico circular
					</Radio.Button>
					<Radio.Button value="bar">
						<BarChartOutlined className="mr-2" />
						Barras vertical
					</Radio.Button>
					<Radio.Button value="bar-h">
						<BarsOutlined className="mr-2" />
						Barras hotizontal
					</Radio.Button>
				</Radio.Group>
			</div>
			<div>
				<div id={`report_${question.id}`}>
					{chartType === 'bar' ? (
						<BarChart
							categories={question?.options?.map((option) => option.key) ?? []}
							questionAnswersCount={answerCounts}
							question={question}
						/>
					) : null}
					{chartType === 'bar-h' ? (
						<BarChart
							categories={question?.options?.map((option) => option.key) ?? []}
							questionAnswersCount={answerCounts}
							horizontal
							question={question}
						/>
					) : null}
					{chartType === 'pie' ? (
						<PieChart
							categories={question?.options?.map((option) => option.key) ?? []}
							questionAnswersCount={answerCounts}
							question={question}
						/>
					) : null}
				</div>
			</div>
		</Spin>
	);
};

export default MultipleChoiceInputReport;
