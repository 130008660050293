import { useEffect } from 'react';
import { useGetPartialFilledAdvisoryByHashQuery } from '../../redux-store/services/advisoryApi';
import { Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

function PartialFilledAdvisory() {
	const { id } = useParams();
	const navigate = useNavigate();
	const {
		data: partialAnswers,
		isLoading: isLoadinPartialAnswers,
		isSuccess,
		error,
	} = useGetPartialFilledAdvisoryByHashQuery(id ?? '');

	useEffect(() => {
		if (error) {
			navigate('/not-found');
		}
	}, [error]);

	useEffect(() => {
		if (isSuccess) {
			navigate(`/advisory/${partialAnswers?.projectHash ?? ''}`, {
				state: { partialAnswers: partialAnswers?.form },
			});
		}
	}, [isSuccess]);

	return (
		<div className="relative ">
			<div
				className="my-0"
				style={{
					textAlign: 'center',
				}}
			>
				<Spin spinning={isLoadinPartialAnswers}></Spin>
			</div>
		</div>
	);
}

export default PartialFilledAdvisory;
