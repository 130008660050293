import { useEffect } from 'react';
import ReportContainer from '../../components/report/ReportContainer';
import TitleContainer from '../../components/home/projects/TitleContainer';
import { useGetAdvisoryReportByHashQuery } from '../../redux-store/services/advisoryApi';
import { Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyGetLaboratoryByIdQuery } from '../../redux-store/services/laboratoryApi';
import { useDispatch } from 'react-redux';
import { setLaboratory, setLogoUrl } from '../../redux-store/features/global';

function Home() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		data: project,
		isLoading: isLoadingProject,
		isSuccess,
		error,
	} = useGetAdvisoryReportByHashQuery(id ?? '');

	const [
		getLaboratory,
		{
			data: lab,
			isFetching: isFetchingLab,
			error: errorLab,
			isSuccess: isSuccessGetLab,
		},
	] = useLazyGetLaboratoryByIdQuery();

	useEffect(() => {
		if (error || errorLab) {
			navigate('/not-found');
		}
	}, [error, errorLab]);

	useEffect(() => {
		if (isSuccess) {
			void getLaboratory(project.idLaboratory);
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isSuccessGetLab) {
			void dispatch(setLogoUrl(lab?.logo ? lab.logo : null));
			void dispatch(setLaboratory(lab ? lab : null));
		}
	}, [isSuccessGetLab]);

	return (
		<div className="relative ">
			<div
				className="my-0"
				style={{
					textAlign: 'center',
				}}
			>
				<Spin spinning={isLoadingProject || isFetchingLab}>
					{lab && project ? (
						<div>
							<TitleContainer laboratory={lab} project={project} />
							<ReportContainer project={project} />
						</div>
					) : null}
				</Spin>
			</div>
		</div>
	);
}

export default Home;
