import { Layout, Row, Col, Typography } from 'antd';
import logoImg from '../../assets/images/logoAdvisories.png';

const { Link } = Typography;
const { Footer } = Layout;

export default function FooterContainer() {
	return (
		<Footer className="bg-white pb-2 pt-4 shadow-2xl drop-shadow-2xl">
			<div className="container mx-auto px-4 ">
				<Row>
					<Col
						xs={24}
						sm={24}
						md={18}
						lg={18}
						xl={18}
						className="order-2 md:order-1 flex items-center"
					>
						<Row gutter={[8, 8]}>
							<Col className="mr-8">
								@2023 LiquidCare. Todos los derechos reservados.
							</Col>
							{/* <Col>
								<Link
									href="/privacy-policy"
									target="_blank"
									className="text-blue-500 hover:underline mr-4"
								>
									Política de privacidad
								</Link>
							</Col>
							<Col>
								<Link
									href="/terms-and-conditions"
									target="_blank"
									className="text-blue-500 hover:underline"
								>
									Términos y condiciones
								</Link>
							</Col> */}
						</Row>
					</Col>
					<Col
						xs={24}
						sm={24}
						md={6}
						lg={6}
						xl={6}
						className="order-1 md:order-2"
					>
						<div className="w-full flex items-center justify-end">
							<span className="text-right">Desarrollado por</span>
							<img
								src={logoImg}
								alt="Your Logo"
								className="ml-3 max-w-full  h-[100px] mt-[-40px]"
							/>
						</div>
					</Col>
				</Row>
			</div>
		</Footer>
	);
}
