import IQuestion from '../models/IQuestion';
import IProjectAnswer from '../models/answers/IProjectAnswer';
import IQuestionAnswers from '../models/answers/IQuestionAnswers';

function formatSingleChoiceAnswer(
	answers: Record<string, string>,
	question: IQuestion
): IQuestionAnswers {
	const result: IQuestionAnswers = {
		id: question.id,
		answers: [],
	};

	question.options?.forEach((option, index) => {
		if (answers[`input_${question.id}`] === option.key) {
			result.answers.push({
				value: option.key,
				openField: option.open
					? answers[`input_${question.id}_open_${index}`]
					: undefined,
			});
		}
	});
	return result;
}

function formatMultipleChoiceAnswer(
	answers: Record<string, string[] | string>,
	question: IQuestion
): IQuestionAnswers {
	const result: IQuestionAnswers = {
		id: question.id,
		answers: [],
	};
	question.options?.forEach((option, index) => {
		if (answers[`input_${question.id}`]?.includes(option.key)) {
			result.answers.push({
				value: option.key,
				openField: option.open
					? (answers[`input_${question.id}_open_${index}`] as string)
					: undefined,
			});
		}
	});
	return result;
}

export function adapterFromFormToApi(
	answers: any,
	questions: IQuestion[]
): IProjectAnswer {
	const answersToSubmit: IProjectAnswer = {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		name: answers.name,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		workplace: answers.hospital,
		projectAnswers: [],
	};

	questions.forEach((question) => {
		switch (question.answerType) {
			case 'text':
			case 'number':
				answersToSubmit.projectAnswers?.push({
					id: question.id,
					answers: [
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
						{ value: answers[`input_${question.id}`] },
					],
				});
				break;
			case 'singleChoice':
				answersToSubmit.projectAnswers?.push(
					formatSingleChoiceAnswer(answers as Record<string, string>, question)
				);
				break;
			case 'multipleChoice':
				answersToSubmit.projectAnswers?.push(
					formatMultipleChoiceAnswer(
						answers as Record<string, string | string[]>,
						question
					)
				);
				break;
			default:
				break;
		}
	});
	return answersToSubmit;
}
