import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { selectLogoUrl } from '../../redux-store/features/global';
import logoImg from '../../assets/images/logoAdvisories.png';

const { Header } = Layout;

export default function HeaderContainer(props: { className?: string | null }) {
	const logoUrl = useSelector(selectLogoUrl);

	return (
		<div>
			<Header
				className={`${
					props.className ?? ''
				} w-full h-auto flex px-5 bg-gray-100 bg-white`}
				style={{
					position: 'sticky',
					top: 10,
					zIndex: 1,
					flexDirection: 'column',
				}}
			>
				<div className="w-full flex flex  justify-between mt-2 pb-4">
					<div className="logo-header py-3  flex items-center w-full">
						<img
							src={logoUrl ?? ''}
							alt="logo"
							className="lg:ml-10 h-[70px] "
						/>
					</div>
					<img
						src={logoImg}
						alt="Your Logo"
						className="ml-3 max-w-full  h-[100px]"
					/>
				</div>
			</Header>
		</div>
	);
}
