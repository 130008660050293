import { Button, Modal, Input } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLaboratory } from '../../../redux-store/features/global';

interface SaveLaterModalProps {
	handleOk: (email: string) => void;
	handleCancel: () => void;
}

const SaveLaterModal = (props: SaveLaterModalProps) => {
	const { handleOk, handleCancel } = props;
	const [email, setEmail] = useState<string | null>(null);
	const laboratory = useSelector(selectLaboratory);

	const validateEmail = (emailToValidate: string) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(emailToValidate);
	};

	return (
		<Modal
			title="Guardar formulario en el estado actual"
			open={true}
			okText="Guardar"
			onOk={() => handleOk(email ?? '')}
			onCancel={handleCancel}
			footer={
				<div>
					<Button onClick={handleCancel}>Cancelar</Button>
					<Button
						className="font-semibold disabled:opacity-50"
						style={{
							background: laboratory?.primaryColor,
							color: laboratory?.secondaryColor,
						}}
						onClick={() => handleOk(email ?? '')}
						disabled={!email || !validateEmail(email)}
					>
						Guardar
					</Button>
				</div>
			}
		>
			<div className="mx-5 my-10">
				<div className="mb-4">Correo electrónico:</div>
				<Input
					onChange={(e) => setEmail(e?.target?.value)}
					value={email ?? ''}
					status={!email || validateEmail(email) ? undefined : 'error'}
				/>
			</div>
		</Modal>
	);
};

export default SaveLaterModal;
