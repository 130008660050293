import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '..';
import ILaboratory from '../../tools/models/ILaboratory';

// Define a type for the slice state
interface GlobalState {
	logoUrl: string | null;
	authToken: string | null;
	laboratory: ILaboratory | null;
}

// Define the initial state using that type
const initialState: GlobalState = {
	logoUrl: null,
	authToken: null,
	laboratory: null,
};

export const globalSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		setLaboratory: (state, action: PayloadAction<ILaboratory | null>) => {
			state.laboratory = action.payload;
		},
		setLogoUrl: (state, action: PayloadAction<string | null>) => {
			state.logoUrl = action.payload;
		},
		setAuthToken: (state, action: PayloadAction<string>) => {
			state.authToken = action.payload;
		},
	},
});

export const { setLogoUrl, setAuthToken, setLaboratory } = globalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLogoUrl = (state: RootState): string | null =>
	state.global.logoUrl;

export const selectAuthToken = (state: RootState): string | null =>
	state.global.authToken;

export const selectLaboratory = (state: RootState): ILaboratory | null =>
	state.global.laboratory;

export default globalSlice.reducer;
