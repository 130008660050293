import { Form, Input } from 'antd';
import IQuestion from '../../../../tools/models/IQuestion';
const { TextArea } = Input;

const OpenFieldTextArea = (props: { question: IQuestion }) => {
	const { question } = props;

	return (
		<Form.Item
			name={`input_${question.id}`}
			className="px-4 py-2"
			label={question.text}
			labelCol={{ span: 24 }}
			rules={[
				{
					required: true,
					message: '',
				},
			]}
		>
			<TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
		</Form.Item>
	);
};

export default OpenFieldTextArea;
