import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { selectLaboratory } from '../../../../../redux-store/features/global';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { downloadImage } from '../../../../../tools/downloadImage';
import IQuestion from '../../../../../tools/models/IQuestion';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
);

const BarChart = (props: {
	categories: string[];
	questionAnswersCount: number[];
	horizontal?: boolean;
	question: IQuestion;
}) => {
	const laboratory = useSelector(selectLaboratory);
	const { categories, questionAnswersCount, horizontal, question } = props;

	const options = {
		indexAxis: horizontal ? ('y' as const) : ('x' as const),
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const,
			},
			title: {
				display: false,
			},
		},
		maintainAspectRatio: false,
	};

	const data = {
		labels: categories,
		datasets: [
			{
				label: 'Cantidad de respuestas',
				data: questionAnswersCount,
				borderWidth: 1,
				backgroundColor: laboratory?.primaryColor ?? 'rgba(75, 192, 192, 0.6)',
			},
		],
	};

	return (
		<div className="mb-4 flex flex justify-center max-h-[500px] items-end">
			<div className="max-w-[400px]" id={`report_bar_${question.id}`}>
				<Bar options={options} data={data} height={300} />
			</div>
			<Button
				className="ml-4 download-img-btn"
				onClick={() => downloadImage(`report_bar_${question.id}`)}
				icon={<DownloadOutlined />}
			/>
		</div>
	);
};

export default BarChart;
