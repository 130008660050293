import ILaboratory from '../../tools/models/ILaboratory';
import IProject from '../../tools/models/IProject';
import IProjectAnswer from '../../tools/models/answers/IProjectAnswer';
import FormComponent from './projects/FormComponent';

export default function HomeContainer(props: {
	laboratory: ILaboratory;
	project: IProject;
	partialAnswers?: IProjectAnswer;
}) {
	const { project, laboratory, partialAnswers } = props;
	return (
		<div className="px-1 pt-6 flex ">
			<FormComponent
				project={project}
				laboratory={laboratory}
				partialAnswers={partialAnswers}
			/>
		</div>
	);
}
