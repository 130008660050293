import { Col, Row } from 'antd';
import ILaboratory from '../../tools/models/ILaboratory';
import IProject from '../../tools/models/IProject';
import ProjectSectionReport from './projects/ProjectSectionReport';

export default function HomeContainer(props: { project: IProject }) {
	const { project } = props;
	return (
		<Row className="w-full justify-center advisory">
			<Col xs={22} sm={22} md={18} lg={18} xl={18}>
				<div className="w-full mx-auto mt-5  px-5 md:px-10 py-6 mb-20 bg-white rounded-lg shadow-lg">
					<div className="px-1 pt-6">
						{project.projectSections.map((projectSection) => (
							<ProjectSectionReport
								key={projectSection.id}
								projectSection={projectSection}
							/>
						))}
					</div>
				</div>
			</Col>
		</Row>
	);
}
