import { WordFrequency } from './models/charts/WordFrequency';
import IProject from './models/IProject';
import IQuestion from './models/IQuestion';
import IProjectAnswer from './models/answers/IProjectAnswer';
import IQuestionAnswers from './models/answers/IQuestionAnswers';

interface Interval {
	start: number;
	end: number;
	numbers: number[];
}

export function getQuestionsFromProject(project: IProject) {
	const questions: IQuestion[] = [];
	project.projectSections.forEach((projectSection) => {
		projectSection.questions.forEach((question) => {
			questions.push(question);
		});
	});
	return questions;
}

export function extractAndCountWords(text: string): WordFrequency[] {
	// Convert text to lowercase and remove non-alphanumeric characters
	const cleanText = text.toLowerCase().replace(/[^\w\s]/g, '');

	// Split the text into an array of words
	let words: string[] = cleanText.split(/\s+/);

	words = words.filter((word) => word.length > 3);

	// Create a Map to store word frequencies
	const wordFrequencies: Map<string, number> = new Map();

	// Count the occurrences of each word
	words.forEach((word: string) => {
		const count = wordFrequencies.get(word) || 0;
		wordFrequencies.set(word, count + 1);
	});

	// Sort the words by frequency in descending order
	const sortedWords: [string, number][] = Array.from(
		wordFrequencies.entries()
	).sort((a, b) => b[1] - a[1]);

	// Extract the top 10 words (adjust as needed)
	const topWords: WordFrequency[] = sortedWords.map(([word, count]) => ({
		text: word,
		value: count,
	}));
	return topWords;
}

export function createIntervals(
	numbers: number[],
	intervalsNumber: number
): Interval[] {
	const sortedNumbers = numbers.sort((a, b) => a - b);
	const intervals: Interval[] = [];
	const intervalSize = Math.ceil(
		(sortedNumbers[sortedNumbers.length - 1] - sortedNumbers[0]) /
			intervalsNumber
	);

	for (let i = 0; i < intervalsNumber; i++) {
		intervals.push({
			start: sortedNumbers[0] + i * intervalSize,
			end: sortedNumbers[0] + (i + 1) * intervalSize,
			numbers: [],
		});
	}

	let currentInterval = 0;
	sortedNumbers.forEach((number) => {
		while (number > intervals[currentInterval].end) {
			currentInterval++;
		}
		intervals[currentInterval].numbers.push(number);
	});

	return intervals;
}
