import IProject from '../../../tools/models/IProject';
import ILaboratory from '../../../tools/models/ILaboratory';

export default function HomeContainer(props: {
	laboratory: ILaboratory;
	project: IProject;
}) {
	const { project, laboratory } = props;
	return (
		<div
			className="project-title px-10 py-8 flex justify-center flex-col flex justify-center items-center text-center lg:text-2xl font-bold"
			style={{
				background: laboratory?.primaryColor,
				color: laboratory?.secondaryColor,
			}}
		>
			{project.name}
		</div>
	);
}
