import { Form, InputNumber } from 'antd';
import IQuestion from '../../../../tools/models/IQuestion';

const OpenFieldNumber = (props: { question: IQuestion }) => {
	const { question } = props;

	return (
		<Form.Item
			name={`input_${question.id}`}
			className="px-4 py-2 input-number"
			label={<div>{question.text}</div>}
			labelCol={{ span: 24 }}
			labelAlign="left"
			rules={[
				{
					required: true,
					message: '',
				},
			]}
		>
			<InputNumber />
		</Form.Item>
	);
};

export default OpenFieldNumber;
