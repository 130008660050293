import html2canvas from 'html2canvas';

export const downloadImage = (id: string) => {
	const div = document.getElementById(id);
	if (!div) return;

	void html2canvas(div).then(function (canvas) {
		const link = document.createElement('a');
		link.download = `${id}.png`;
		link.href = canvas.toDataURL('image/png');
		link.click();
	});
};
