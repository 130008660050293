import ILaboratory from '../../tools/models/ILaboratory';
import { api } from './api';

export const laboratoryApi = api.injectEndpoints({
	endpoints: (build) => ({
		getLaboratoryById: build.query<ILaboratory, number | string>({
			query(id: number | string) {
				return {
					url: `/laboratory/${id}`,
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useGetLaboratoryByIdQuery, useLazyGetLaboratoryByIdQuery } =
	laboratoryApi;
