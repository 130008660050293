import { Form, Input } from 'antd';

const OpenInputField = (props: {
	name: string;
	label: string;
	borders?: boolean;
}) => {
	const { name, label } = props;
	const borders = props.borders === false ? false : true;

	return (
		<Form.Item
			label={`${label}:`}
			name={name}
			rules={[{ required: true, message: '' }]}
			labelCol={{ span: 24 }}
		>
			<Input
				style={{
					borderTop: !borders ? 'unset' : 'auto',
					borderLeft: !borders ? 'unset' : 'auto',
					borderRight: !borders ? 'unset' : 'auto',
					borderRadius: !borders ? '0' : 'auto',
					boxShadow: !borders ? 'unset' : 'auto',
				}}
			/>
		</Form.Item>
	);
};

export default OpenInputField;
