import {
	BaseQueryApi,
	FetchArgs,
	createApi,
	fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../';

// Create our baseQuery instance
const baseQueryErrorHandled = () => {
	const baseQuery = fetchBaseQuery({
		baseUrl: `${
			process.env.REACT_APP_LIQUIDCARE_API_URL ?? 'http://localhost:8000'
		}/api/`,
		prepareHeaders: (headers, { getState }) => {
			// By default, if we have a token in the store, let's use that for authenticated requests
			const token = (getState() as RootState).global.authToken;
			if (token) {
				headers.set(
					'Authorization',
					process.env.REACT_APP_LIQUIDCARE_API_URL
						? `Basic ${process.env.REACT_APP_LIQUIDCARE_API_URL}`
						: ''
				);
			}
			return headers;
		},
	});
	return async (
		args: string | FetchArgs,
		api: BaseQueryApi,
		extraOptions: {}
	) => {
		const result = await baseQuery(args, api, extraOptions);
		return result;
	};
};

// const baseQueryWithRetry = retry(baseQueryErrorHandled(), { maxRetries: 0 });

export const api = createApi({
	baseQuery: baseQueryErrorHandled(),
	tagTypes: ['advisory', 'laboratory'],
	endpoints: () => ({}),
});
