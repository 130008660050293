import Item from 'antd/es/list/Item';
import IProject from '../../tools/models/IProject';
import IProjectApiFormat from '../../tools/models/apiFormat/IProjectApiFormat';
import IProjectSection from '../../tools/models/IProjectSection';
import { api } from './api';
import IOption from '../../tools/models/IOption';
import IProjectAnswer from '../../tools/models/answers/IProjectAnswer';
import { message } from 'antd';
import IQuestion from '../../tools/models/IQuestion';
import IQuestionApiFormat from '../../tools/models/apiFormat/IQuestionApiFormat';
import IUnfinishedProjectAnswer from '../../tools/models/answers/IUnfinishedProjectAnswer';

interface IDefaultSuccessResponse {
	message: string;
}

export const advisoryApi = api.injectEndpoints({
	endpoints: (build) => ({
		getAdvisoryById: build.query<IProject, string | string>({
			query(id: string) {
				return {
					url: `/advisory/${id}`,
					method: 'GET',
				};
			},
			transformResponse: (response: IProjectApiFormat): IProject => {
				return {
					...response,
					projectSections: response.projectSections.map((section) => {
						return {
							...section,
							questions: section.questions.map((question) => {
								return {
									...question,
									options: Object.values(question.options as IOption),
								};
							}),
						};
					}),
				};
			},
		}),
		getPartialFilledAdvisoryByHash: build.query<
			IUnfinishedProjectAnswer,
			string | string
		>({
			query(hash: string) {
				return {
					url: `/advisory/partial/${hash}`,
					method: 'GET',
				};
			},
		}),
		getAdvisoryReportByHash: build.query<IProject, string | string>({
			query(hash: string) {
				return {
					url: `/advisory-report/${hash}`,
					method: 'GET',
				};
			},
			transformResponse: (response: IProjectApiFormat): IProject => {
				return {
					...response,
					projectSections: response.projectSections.map((section) => {
						return {
							...section,
							questions: section.questions.map((question) => {
								return {
									...question,
									options: Object.values(question.options as IOption),
								};
							}),
						};
					}),
				};
			},
		}),
		getQuestionAnswers: build.query<IQuestion, number | string>({
			query(id: number | string) {
				return {
					url: `/advisory/summary/${id}`,
					method: 'GET',
				};
			},
			transformResponse: (response: IQuestionApiFormat): IQuestion => {
				return {
					...response,
					options: Object.values(response.options as IOption),
				};
			},
		}),
		postAdvisoryAnswers: build.mutation<
			IDefaultSuccessResponse,
			IProjectAnswer
		>({
			query(body: IProjectAnswer) {
				return {
					url: `/advisory/answers/${body?.id ?? ''}`,
					method: 'POST',
					body,
				};
			},
		}),
		postUnfinishedAdvisoryAnswers: build.mutation<
			IDefaultSuccessResponse,
			IUnfinishedProjectAnswer
		>({
			query(body: IUnfinishedProjectAnswer) {
				const hash = body?.form?.id ?? '';
				delete body?.form?.id;
				return {
					url: `/advisory/partial-answers/${hash ?? ''}`,
					method: 'POST',
					body,
				};
			},
		}),
	}),
});

export const {
	useGetAdvisoryByIdQuery,
	usePostAdvisoryAnswersMutation,
	useGetQuestionAnswersQuery,
	useGetAdvisoryReportByHashQuery,
	usePostUnfinishedAdvisoryAnswersMutation,
	useGetPartialFilledAdvisoryByHashQuery,
} = advisoryApi;
