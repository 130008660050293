import { useEffect } from 'react';
import './App.css';

import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import Home from './views/home/Home';
import Report from './views/report/Report';
import LayoutContainer from './views/LayoutContainer';
import { ConfigProvider } from 'antd';
import PartialFilledAdvisory from './views/partialFilledAdvisory/PartialFilledAdvisory';

const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

function App() {
	return (
		<ConfigProvider
			theme={{
				token: {
					fontFamily: 'Montserrat',
				},
			}}
		>
			<BrowserRouter>
				<ScrollToTop />
				<Routes>
					<Route path="/advisory/:id" element={<LayoutContainer />}>
						<Route index element={<Home />} />
					</Route>
					<Route path="/advisory/partial/:id" element={<LayoutContainer />}>
						<Route index element={<PartialFilledAdvisory />} />
					</Route>
					<Route path="/advisory/report/:id" element={<LayoutContainer />}>
						<Route index element={<Report />} />
					</Route>

					{/* <Route path="/" element={<DarkLayoutContainer />}>
						<Route path="/employee/:id" element={<EmployeeDetails />} />
					</Route> */}
					<Route path="*" element={<div>Not found</div>} />
				</Routes>
			</BrowserRouter>
		</ConfigProvider>
	);
}

export default App;
